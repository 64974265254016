import React from "react";
import PropTypes from "prop-types";
import TailwindHeader from "./header";
import Footer from "./footer";

const Layout = ({children}) => {
    return (
        <>
            {/* ConvertKit:  SMW - Waitlist - Modal */}
            <script async data-uid="445da8d0f0" src="https://tremendous-pioneer-7859.ck.page/445da8d0f0/index.js"/>

            <TailwindHeader />

            <main>{children}</main>

            <Footer />
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout;