import React, {useState} from "react";
// import {Transition} from "@headlessui/react";
import {useSiteMetadata} from "../hooks/use-site-metadata";
import LogoSmwPng from "../images/svg/logos/smw-logo-250.png";


// function NavLink (props) {
//     const [hover, setHover] = useState(false);
//
//     return (
//         <a href={props.path || "#_"} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}
//             className="relative inline-block text-sm font-bold text-gray-200 uppercase transition duration-150 lg:text-base ease hover:text-white">
//             <span className="block">{props.text}</span>
//             <span className="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">
//                 <Transition show={hover}
//                     enter="transition ease-in-out duration-300"
//                     enterFrom="-translate-x-full"
//                     enterTo="translate-x-0"
//                     leave="transition ease-in-out duration-300"
//                     leaveFrom="translate-x-0"
//                     leaveTo="translate-x-full"
//                     className="absolute inset-0 inline-block w-full h-full transform border-t-2 border-blue-200"
//                     as="span">
//                 </Transition>
//             </span>
//         </a>
//     );
// }

const TailwindHeader = () => {
    // const [loginHover, setLoginHover] = useState(false);
    const siteMedata = useSiteMetadata();

    const [showMenu, setShowMenu] = useState(false);

    const handleMenuClick = (pShowMenu) => {
        setShowMenu(!pShowMenu);
    }

    return (
        // <section class="relative py-2 bg-gradient-to-br from-purple-600 via-indigo-00 to-blue-500">
        //     <div class="flex items-center justify-between h-20 px-8 mx-auto max-w-7xl">
        //         <a href="#_" class="relative z-10 flex items-center w-auto text-2xl font-extrabold leading-none text-white select-none">
        //             {/*{siteMedata.name}*/}
        //             <LogoSMWTitle className="h-20 w-20"/>
        //         </a>
        //
        //         <nav class="items-center justify-center hidden space-x-8 text-gray-200 md:flex">
        //             <NavLink text="Home" path="/"/>
        //
        //             {/*<NavLink text="Features" path="/features"/>*/}
        //
        //             {/*<NavLink text="Pricing" path="/pricing"/>*/}
        //
        //             <NavLink text="Blog" path="/blog"/>
        //
        //             <div class="w-0 h-5 border border-r border-blue-700"></div>
        //
        //             {/*<a href="/login" class="relative inline-block ml-5 text-base font-bold text-gray-200 uppercase transition duration-150 ease hover:text-white"*/}
        //             {/*    onMouseEnter={() => setLoginHover(true)} onMouseLeave={()=>setLoginHover(false)}>*/}
        //             {/*    <span className="block">Login</span>*/}
        //             {/*    <span className="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">*/}
        //             {/*        <span className="absolute inset-0 inline-block w-full h-1 h-full transform translate-x-0 border-t-2 border-blue-200" />*/}
        //             {/*    </span>*/}
        //             {/*    <span className="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">*/}
        //             {/*        <Transition*/}
        //             {/*            show={loginHover}*/}
        //             {/*            enter="transition ease-in-out duration-300"*/}
        //             {/*            enterFrom="-translate-x-full"*/}
        //             {/*            enterTo="translate-x-0"*/}
        //             {/*            leave="transition ease-in-out duration-300"*/}
        //             {/*            leaveFrom="translate-x-0"*/}
        //             {/*            leaveTo="translate-x-full"*/}
        //             {/*            className="absolute inset-0 inline-block w-full h-1 h-full transform border-t-2 border-white"*/}
        //             {/*            as="span">*/}
        //
        //             {/*        </Transition>*/}
        //             {/*    </span>*/}
        //             {/*</a>*/}
        //
        //             {/*<a href="/signup"*/}
        //             {/*   class="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-blue-500 whitespace-no-wrap border border-blue-300 rounded-md shadow-sm bg-blue-50 focus:ring-offset-blue-600 hover:bg-white hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-100">*/}
        //             {/*    Signup*/}
        //             {/*</a>*/}
        //
        //         </nav>
        //
        //         {/* Mobile Button*/}
        //         <div class="flex items-center justify-center h-full text-white md:hidden">
        //             <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        //                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path>
        //             </svg>
        //         </div>
        //     </div>
        // </section>

        <section className="antialiased bg-green-400">

            <div className="px-12 mx-auto max-w-7xl">
                {/* Header Bar */}
                <div className="flex flex-wrap items-center justify-between w-full h-24 text-black">

                    <div className="relative z-10 flex items-center w-auto px-0 leading-10 md:px-2 lg:flex-grow-0 lg:flex-shrink-0 lg:text-left">
                        <a href="#_" className="inline-block font-sans text-2xl font-extrabold text-left text-black no-underline bg-transparent cursor-pointer focus:no-underline">
                            <img src={LogoSmwPng} className="h-20" alt={siteMedata.name + " Logo"}/>
                            {/*<LogoSmw className="h-20" />*/}
                        </a>
                    </div>

                    <div
                        className={`left-0 z-0 flex-col justify-center w-full px-4 space-y-3 font-sans font-extrabold leading-10 md:space-y-0 lg:flex-grow-0 md:flex-row lg:text-center 
                                    text-white capitalize text-3xl ${showMenu ? "flex z-30 top-0 bg-white h-full fixed" : "hidden md:flex absolute"} ` }>

                        <p>{siteMedata.name}</p>
                        <a href="#_" className="inline-block px-5 py-4 text-lg font-medium text-center text-white bg-purple-600 shadow-xl md:hidden hover:bg-purple-700 rounded-2xl">Try for free</a>
                    </div>
                    <div className="relative hidden mt-2 font-medium leading-10 md:inline-block lg:flex-grow-0 lg:flex-shrink-0 lg:mt-0 lg:text-right">
                        {/* ConvertKit - SMW Waitlist - Modal */}
                        <a data-formkit-toggle="445da8d0f0" href="https://tremendous-pioneer-7859.ck.page/445da8d0f0"
                           className="px-5 py-4 text-lg font-medium text-white bg-yellow-700 shadow-xl hover:bg-yellow-800 rounded-2xl capitalize">
                            Join Waitlist
                        </a>
                    </div>

                    <div onClick={() => handleMenuClick(showMenu)} className="absolute right-0 z-40 flex flex-col items-center items-end justify-center w-10 h-10 mr-8 rounded-full cursor-pointer md:hidden hover:bg-gray-100">
                        {!showMenu &&
                            <svg className="w-6 h-6 text-gray-700"  fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        }

                        {showMenu &&
                            <svg className="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        }
                    </div>
                </div>
                {/* End Header Bar */}

            </div>

        </section>


)
}

export default TailwindHeader;