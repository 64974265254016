import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            siteUrl
            description
            twitter
            name
            logo
            staticForms {
              accessKey
            } 
          }
        }
      }
    `
    )
    return site.siteMetadata
}